import React, { useState } from 'react';
import { Card, Button, Elevation, H5, Alert, Tooltip, Icon } from '@blueprintjs/core';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { type Simulation } from '../api/cppmodelApi';


export const SimulationList: React.FC = () => {
    const navigate = useNavigate();
    const simulations = useLoaderData() as Simulation[];

    // const [simulations, setSimulations] = useState<Simulation[]>([
    //     { id: 1, name: 'Simulation A' },
    //     { id: 2, name: 'Simulation B' },
    //     { id: 3, name: 'Simulation C' },
    // ]);

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [simulationToDelete, setSimulationToDelete] = useState<Simulation | null>(null);

    const handleVisitSimulation = (simulation: Simulation) => {
        navigate(`/results/${simulation.name}`, { state: { simulationName: simulation.name } });
    };

    const handleDeleteClick = (simulation: Simulation) => {
        setSimulationToDelete(simulation);
        setIsDeleteAlertOpen(true);
    };

    const confirmDelete = () => {
        // if (simulationToDelete) {
        //     setSimulations(simulations.filter(sim => sim.id !== simulationToDelete.id));
        //     setIsDeleteAlertOpen(false);
        //     setSimulationToDelete(null);
        // }
        console.log('Delete simulation:', simulationToDelete?.name);
    };

    const cancelDelete = () => {
        setIsDeleteAlertOpen(false);
        setSimulationToDelete(null);
    };

    return (
        <div style={{ padding: '20px' }}>
            <H5>All Simulations</H5>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {simulations.map(simulation => (
                    <Card key={simulation.id} interactive={true} elevation={Elevation.TWO}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <H5>{simulation.name}{` (${simulation.lastUsedBy})`}</H5>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <Icon icon={IconNames.TickCircle} />
                                <Button icon="eye-open" intent="primary" onClick={() => handleVisitSimulation(simulation)}>
                                    Visit Simulation
                                </Button>
                                <Button icon="trash" intent="danger" onClick={() => handleDeleteClick(simulation)}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                        {/* <div style={{ height: '150px', backgroundColor: '#f5f5f5', marginTop: '10px', borderRadius: '5px' }}>
                            <Icon icon="timeline-line-chart" iconSize={50} />
                            <span style={{ marginLeft: '10px' }}>Simulation Graph</span>
                        </div> */}
                    </Card>
                ))}
            </div>

            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                intent="danger"
                isOpen={isDeleteAlertOpen}
                onCancel={cancelDelete}
                onConfirm={confirmDelete}
            >
                <p>
                    Are you sure you want to delete the simulation <strong>{simulationToDelete?.name}</strong>?
                </p>
            </Alert>
        </div>
    );
};
