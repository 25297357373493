import { User } from "oidc-client-ts"

export interface Simulation {
    id: number;
    name: string;
    lastUsedBy: string;
}

function getUser() {
    const oidcStorage = sessionStorage.getItem('oidc.user:https://auth.cppmodel.com/realms/CppModel:cppmodel-frontend')
    if (!oidcStorage) {
        return null;
    }
    // console.log('User:', oidcStorage);

    return User.fromStorageString(oidcStorage);
}

export const getAllUserSimulations = async () => {
    const user = getUser();
    const token = user?.access_token;
    // fetch simulations from localhost usign bearer token
    // fetch("/api/simulations", {
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     },
    // }).then((response) => {
    //     console.log(response);
    // }).catch((error) => {
    //     console.error(error);
    // });
    // console.log(`Getting simulations for ${user?.profile.email}`);
    
    return [
        { id: 1, name: 'Simulation A', lastUsedBy: user?.profile.email },
        { id: 3, name: 'Simulation C', lastUsedBy: user?.profile.email },
        { id: 4, name: 'Simulation D', lastUsedBy: user?.profile.email },
    ];
}

export const getAllTeamSimulations = async () => {
    const user = getUser();
    // const token = user?.access_token;
    const groups = user?.profile.groups as string[];
    console.log(`Getting simulations for ${groups}`);
    
    return [
        { id: 1, name: 'Simulation A', lastUsedBy: user?.profile.email },
        { id: 2, name: 'Simulation B', lastUsedBy: 'someone@else.com' },
        { id: 3, name: 'Simulation C', lastUsedBy: user?.profile.email },
        { id: 4, name: 'Simulation D', lastUsedBy: user?.profile.email },
    ];
}

// export const getPosts = createAsyncThunk(
//     "store/getPosts",
//     async () => {
//         const user = getUser();
//         const token = user?.access_token;
//         return fetch("https://api.example.com/posts", {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     },
//     // ...
// )