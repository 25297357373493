import './App.css';
import { MainNavigation } from './components/MainNavigation';
import { AllSimulations } from './components/AllSimulations';
import { BrowserRouter, createBrowserRouter, Outlet, Route, RouterProvider, Routes } from 'react-router-dom';
import SimulationResults from './components/SimulationResults';

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
// import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
// import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
// import "@blueprintjs/select/lib/css/blueprint-select.css";
import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
import { getAllTeamSimulations, getAllUserSimulations } from './api/cppmodelApi';


const HomePage: React.FC = () => {
  return <div>
    {/* Navigation Bar */}
    <MainNavigation />
    <main>
      {/* {navigation.state === 'loading' && <p>Loading...</p>} */}
      <Outlet />
    </main>
  </div>
};
const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    id: 'root',
    children: [
      {
        index: true,
        // path: '/user-simulations',
        element: <AllSimulations scope='user' />,
        loader: getAllUserSimulations
      },
      {
        path: "/team-simulations",
        element: <AllSimulations scope='team' />,
        loader: getAllTeamSimulations

      },
      {
        path: "/results/:simulationId",
        element: <SimulationResults />
      }
    ]
  }]);

const App: React.FC = () => {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading) {
      auth.signinRedirect(); // Redirect to login if not authenticated
    } else {
      if (window.location.hostname === "workspace.cppmodel.com") {
        const token = auth.user?.profile as { groups?: string[] };

        if (token && token.groups && token.groups.length > 0) {
          // Extract the first group and construct the subdomain
          let firstGroup = token.groups[0];
          if (firstGroup === "localhost") {
            window.location.href = "http://localhost:3000";
          } else {
            const domain = "cppmodel.com";

            // Construct the subdomain URL
            const subdomainUrl = `https://${firstGroup}.${domain}`;

            // Redirect to the constructed URL
            window.location.href = subdomainUrl;
          }
        } else {
          console.error("No groups found in the token.");
          // Handle cases where no groups are available
        }
      }
    }
  }, [auth.isAuthenticated, auth.isLoading, auth]);
  if (auth.isLoading) return <div>Loading...</div>; // Optional loading message

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  // Render the protected content only if authenticated
  return auth.isAuthenticated ? (
    <RouterProvider router={router} />
  ) : null;
};

export default App;
